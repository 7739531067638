import React, {useEffect,useState} from 'react';
import {useWindowDimensions ,Linking,Image,ActivityIndicator,Pressable, StatusBar, StyleSheet, Text, TouchableOpacity, View, SafeAreaView} from 'react-native';
import {Audio} from 'expo-av';
import {LinearGradient} from 'expo-linear-gradient';
import {FontAwesome,Ionicons} from '@expo/vector-icons';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import Slider from '@react-native-community/slider';
// import Linking from 'expo-linking';

export default function App({navigation}) {
    const window = useWindowDimensions();

    const width=window.width

    const [sound, setSound] = useState();
    const [play,setPlay] =useState(false)
    const [load,setLoad] =useState(false)
    const [volume,setVolume] =useState(0.8)

    async function playSound() {
        setLoad(true)
        console.log('Loading Sound');
        await Audio.setAudioModeAsync(
            {
                playsInSilentModeIOS: true,
                staysActiveInBackground: true,
                interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DUCK_OTHERS,
                shouldDuckAndroid: true,
                interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DUCK_OTHERS,
                allowsRecordingIOS: false,
            }
        )
        const {sound} = await Audio.Sound.createAsync({uri: 'https://cloudcast.pvtwebs.com/proxy/rajahra4?mp=/live'})
        setLoad(false)
        await sound.setVolumeAsync(volume)
        await sound.playAsync();
        setSound(sound);
    }

    async function stopsound() {
        await sound.stopAsync();
        setPlay(!play)
    }

    useEffect(() => {
      //  if(width>500){
        playSound()
      //  }else {
       //     setPlay(false)
      //  }
    }, [])

    React.useEffect(() => {
        return sound
            ? () => {
                console.log('Unloading Sound');
                sound.unloadAsync();
            }
            : undefined;
    }, [sound]);

    return (
        <SafeAreaView style={styles.container}>
            <StatusBar  backgroundColor='#4a007d' barStyle='default'/>
            <LinearGradient
                colors={['#4a007d', '#3b5998', '#d95afa']}
                style={styles.background}
            />
            <View style={{flex:4,width:wp(100)}}>

            <View
                style={{flex:4,padding:hp(3),
                    justifyContent: 'flex-start',alignSelf:'flex-start'}}
            >
            <TouchableOpacity
                style={{justifyContent: 'center'}}
                onPress={() => navigation.toggleDrawer()}>
                <FontAwesome name='navicon' color='white'
                             style={{ fontSize: 30}}
                />
            </TouchableOpacity>
            </View>
            <View style={{flex: 5,justifyContent: 'center',alignItems: 'center'}}>

                <Text style={{color: 'white', fontSize: 44}}>SLBM Radio</Text>
            </View>
            </View>
            <View style={{flex:1,alignContent:'center',justifyContent:'center'}}>
                <Text style={{fontSize:18,color:'#ffffff80'}}>www.slbmradio.org</Text>
            </View>
            <View style={{flex:6,justifyContent: 'center'}}>
                <Image
                    resizeMode = 'stretch'
                    style={{height:hp(25),width:wp(80),maxWidth:300,
                        borderRadius:30}}
                    source={require('../assets/logo_small.png')} />
            </View>
            <View style={{flex:1,alignItems :'center'}}>
                <Text style={{fontSize:25,color:'#ffffff80'}}>Srilanka Bride Ministry</Text>
            </View>
            <View style={{flex:7,justifyContent: 'space-around'}}>
                <View >
                    <Pressable
                        onPress={()=>{
                            if(!play){
                                playSound()
                                setPlay(!play)
                            }else {
                                stopsound()
                                setPlay(!play)
                            }
                            setPlay(!play)
                        }}
                        style={{height:hp(15),width:hp(15),alignSelf: 'center',justifyContent: 'center'}}>
                        {!load?(

                <Ionicons  size={hp(15)} name={play?'pause-circle-sharp':'play-circle-sharp'} color='#ffffff70'/>
                        ):<ActivityIndicator size='large'  color='#ff5998'/>}
                </Pressable>
                </View>
                <View>
                <Slider
                    value={volume}
                    style={{width: 200, height: 20}}
                    minimumValue={0}
                    onValueChange={async (value) => {
                        await setVolume(value)
                        await sound.setVolumeAsync(volume)
                    }}
                    maximumValue={1}
                    minimumTrackTintColor="#FFFFFF"
                    maximumTrackTintColor="#000000"
                />
                <Text style={{alignSelf:'center',color:'#ffffff70'}}>Volume</Text>
                </View>
            </View>
            <View style={{flex:1,marginBottom:50}}>
                <TouchableOpacity
                    onPress={()=> {
                        Linking.openURL('tel://+94776471198');
                    }}
                    style={{flexDirection:'row'}}>
                    <Ionicons name="call" size={20} color="#FFFFF150" />
                    <Text style={{fontSize:15,color:'#ffffff80'}}> +94 77 647 1198</Text>
                </TouchableOpacity>
            </View>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
       // height: hp(100),
        width:wp(100)
    },
    background: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        height: hp(100),
    },
});
