import React from 'react';
import {View,Text ,Linking} from 'react-native';
import {NavigationContainer} from '@react-navigation/native';
import {
    createDrawerNavigator,
    DrawerContentScrollView,
    DrawerItemList,
    DrawerItem,
} from '@react-navigation/drawer';
import HomeScreen from "./HomeScreen";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
//import Linking from 'expo-linking';

const Drawer = createDrawerNavigator();

const Routes = () =>{

    function CustomDrawerContent(props) {
        return (
            <DrawerContentScrollView {...props} >
                <View style={{height:hp(50)}}>

                <DrawerItemList {...props} />
                <DrawerItem label="FaceBook" onPress={() => Linking.openURL('https://www.facebook.com/Srilanka-Bride-Ministry-105666128166482/')} />
                <DrawerItem label="Youtube" onPress={() => Linking.openURL('https://youtube.com/channel/UCoCnzu5iWuNxE6DlRheJmKQ')} />
                </View>
           <View style={{opacity:0.4,height:hp(40),justifyContent:'flex-end',alignItems:'center'}}>
               <Text style={{fontSize:14}}>Develop by</Text>
               <Text style={{fontSize:10}}>www.debaly.com</Text>
           </View>
            </DrawerContentScrollView>
        );
    }

    return (
        <NavigationContainer>
            <Drawer.Navigator
                drawerContent={props => <CustomDrawerContent {...props} />}
                drawerStyle={{
                    width: wp(60),
                    maxWidth:300,
                    height:hp(100),

                }}
                initialRouteName="SLBM Radio"
                >
                <Drawer.Screen name="SLBM Radio" component={HomeScreen} />
                {/*<Drawer.Screen name="Contact Us" component={ContactUsScreen} />*/}
            </Drawer.Navigator>
        </NavigationContainer>
    );
}

export default Routes;
