import React from 'react';
import Routes from "./src/Routes";

export default function App() {
  return (
    <Routes/>
  );
}

// <meta name="description" content="SLBM Radio is the Christian's religious online radio station by SriLanka Bride Ministry slbmradio.org slbmradio.com">
//   <meta name="robots" content="noindex, nofollow" />
//   <meta name="keywords" content="SLBM,,Online,christian,Radio,Srilanka, Bride, Ministry SBM,religion">
